<template>
  <OnboardPage 
    :content="content"
  >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Name of business"
        v-model.trim="$v.business.name.$model"
        :validation="$v.business.name"
      />
      <BaseTextarea
        label="Description" 
        v-model.trim="$v.business.about.$model"
        :validation="$v.business.about"
      />
      <BaseSubmit
        v-if="content.showNext" 
        :error="error"
        :label="content.showNextLabel"
        :disabled="$v.$invalid"
        :submitting="submitting"
      />
    </form>
    <a
      v-if="content.showBack" 
      class="is-size-7 has-text-grey"
      @click="back()"
    >
      &larr;
      back
    </a>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import { required } from 'vuelidate/lib/validators';
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    Notification,
    BaseInput,
    BaseTextarea,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      business: {
        name: '',
        about: ''
      },
      content: {
        page: 'organization-edit',
        step: 2,
        title: 'Business',
        subtitle: 'Update your business name and describe what you do',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-locations",
        showNextLabel: "Next",
        showNext: true,
        showBack: false
      }
    }
  },
  validations: {
    business: {
      name: { required },
      about: { required }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {
      
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.business = this.currentBusiness
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: this.business
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.submitting = false
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.submitting = false
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>
